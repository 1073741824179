import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrival, LazySvgoArrow2, LazySvgoArrow3, LazySvgoArrowLong, LazySvgoArrow, LazySvgoArrowsSwap, LazySvgoBenefitsFast, LazySvgoBenefitsFlexible, LazySvgoBenefitsJourneys, LazySvgoBenefitsOperators, LazySvgoBenefitsRewards, LazySvgoBicycle, LazySvgoBurger, LazySvgoCalendar, LazySvgoCardsAtom, LazySvgoCardsBell, LazySvgoCardsCalendar, LazySvgoCardsCart, LazySvgoCardsClock, LazySvgoCardsGlobe, LazySvgoCardsInspiration, LazySvgoCardsLike, LazySvgoCardsPhone, LazySvgoCardsScrew, LazySvgoCardsTech, LazySvgoCardsTicket, LazySvgoCardsUser, LazySvgoCartBenefitsChat, LazySvgoCartBenefitsClock, LazySvgoCartBenefitsShield, LazySvgoCartBenefitsStar, LazySvgoCart, LazySvgoChange, LazySvgoCheck2, LazySvgoCheckTransparent, LazySvgoCheckWide, LazySvgoCheck, LazySvgoChevronDownSmall, LazySvgoChevronDownWide, LazySvgoChevronDown, LazySvgoChevronNavigation, LazySvgoChevronRight, LazySvgoChild, LazySvgoClock, LazySvgoCross, LazySvgoDeparture, LazySvgoEdit, LazySvgoEmail, LazySvgoFile, LazySvgoInfo, LazySvgoLocation, LazySvgoLogo, LazySvgoLuggage, LazySvgoMinus, LazySvgoPlus, LazySvgoQuestionMark, LazySvgoRecent, LazySvgoRefresh, LazySvgoReturn, LazySvgoSearchAlt, LazySvgoSearch, LazySvgoServicesBar, LazySvgoServicesFood, LazySvgoServicesLightning, LazySvgoServicesLounge, LazySvgoServicesLuggage, LazySvgoServicesPower, LazySvgoServicesRestaurant, LazySvgoServicesWifi, LazySvgoSettings2, LazySvgoSettings, LazySvgoShare, LazySvgoShareMessenger, LazySvgoShareTelegram, LazySvgoShareWhatsapp, LazySvgoSingle2, LazySvgoSingle, LazySvgoSocialsInstagram, LazySvgoSocialsTiktok, LazySvgoSocialsX, LazySvgoSocialsYoutube, LazySvgoStorage, LazySvgoSuggestions, LazySvgoTicket, LazySvgoTrain2, LazySvgoTrain, LazySvgoTrash, LazySvgoUserMobile, LazySvgoUser, LazySvgoWarning } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrival", LazySvgoArrival],
["SvgoArrow2", LazySvgoArrow2],
["SvgoArrow3", LazySvgoArrow3],
["SvgoArrowLong", LazySvgoArrowLong],
["SvgoArrow", LazySvgoArrow],
["SvgoArrowsSwap", LazySvgoArrowsSwap],
["SvgoBenefitsFast", LazySvgoBenefitsFast],
["SvgoBenefitsFlexible", LazySvgoBenefitsFlexible],
["SvgoBenefitsJourneys", LazySvgoBenefitsJourneys],
["SvgoBenefitsOperators", LazySvgoBenefitsOperators],
["SvgoBenefitsRewards", LazySvgoBenefitsRewards],
["SvgoBicycle", LazySvgoBicycle],
["SvgoBurger", LazySvgoBurger],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCardsAtom", LazySvgoCardsAtom],
["SvgoCardsBell", LazySvgoCardsBell],
["SvgoCardsCalendar", LazySvgoCardsCalendar],
["SvgoCardsCart", LazySvgoCardsCart],
["SvgoCardsClock", LazySvgoCardsClock],
["SvgoCardsGlobe", LazySvgoCardsGlobe],
["SvgoCardsInspiration", LazySvgoCardsInspiration],
["SvgoCardsLike", LazySvgoCardsLike],
["SvgoCardsPhone", LazySvgoCardsPhone],
["SvgoCardsScrew", LazySvgoCardsScrew],
["SvgoCardsTech", LazySvgoCardsTech],
["SvgoCardsTicket", LazySvgoCardsTicket],
["SvgoCardsUser", LazySvgoCardsUser],
["SvgoCartBenefitsChat", LazySvgoCartBenefitsChat],
["SvgoCartBenefitsClock", LazySvgoCartBenefitsClock],
["SvgoCartBenefitsShield", LazySvgoCartBenefitsShield],
["SvgoCartBenefitsStar", LazySvgoCartBenefitsStar],
["SvgoCart", LazySvgoCart],
["SvgoChange", LazySvgoChange],
["SvgoCheck2", LazySvgoCheck2],
["SvgoCheckTransparent", LazySvgoCheckTransparent],
["SvgoCheckWide", LazySvgoCheckWide],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronDownSmall", LazySvgoChevronDownSmall],
["SvgoChevronDownWide", LazySvgoChevronDownWide],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronNavigation", LazySvgoChevronNavigation],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChild", LazySvgoChild],
["SvgoClock", LazySvgoClock],
["SvgoCross", LazySvgoCross],
["SvgoDeparture", LazySvgoDeparture],
["SvgoEdit", LazySvgoEdit],
["SvgoEmail", LazySvgoEmail],
["SvgoFile", LazySvgoFile],
["SvgoInfo", LazySvgoInfo],
["SvgoLocation", LazySvgoLocation],
["SvgoLogo", LazySvgoLogo],
["SvgoLuggage", LazySvgoLuggage],
["SvgoMinus", LazySvgoMinus],
["SvgoPlus", LazySvgoPlus],
["SvgoQuestionMark", LazySvgoQuestionMark],
["SvgoRecent", LazySvgoRecent],
["SvgoRefresh", LazySvgoRefresh],
["SvgoReturn", LazySvgoReturn],
["SvgoSearchAlt", LazySvgoSearchAlt],
["SvgoSearch", LazySvgoSearch],
["SvgoServicesBar", LazySvgoServicesBar],
["SvgoServicesFood", LazySvgoServicesFood],
["SvgoServicesLightning", LazySvgoServicesLightning],
["SvgoServicesLounge", LazySvgoServicesLounge],
["SvgoServicesLuggage", LazySvgoServicesLuggage],
["SvgoServicesPower", LazySvgoServicesPower],
["SvgoServicesRestaurant", LazySvgoServicesRestaurant],
["SvgoServicesWifi", LazySvgoServicesWifi],
["SvgoSettings2", LazySvgoSettings2],
["SvgoSettings", LazySvgoSettings],
["SvgoShare", LazySvgoShare],
["SvgoShareMessenger", LazySvgoShareMessenger],
["SvgoShareTelegram", LazySvgoShareTelegram],
["SvgoShareWhatsapp", LazySvgoShareWhatsapp],
["SvgoSingle2", LazySvgoSingle2],
["SvgoSingle", LazySvgoSingle],
["SvgoSocialsInstagram", LazySvgoSocialsInstagram],
["SvgoSocialsTiktok", LazySvgoSocialsTiktok],
["SvgoSocialsX", LazySvgoSocialsX],
["SvgoSocialsYoutube", LazySvgoSocialsYoutube],
["SvgoStorage", LazySvgoStorage],
["SvgoSuggestions", LazySvgoSuggestions],
["SvgoTicket", LazySvgoTicket],
["SvgoTrain2", LazySvgoTrain2],
["SvgoTrain", LazySvgoTrain],
["SvgoTrash", LazySvgoTrash],
["SvgoUserMobile", LazySvgoUserMobile],
["SvgoUser", LazySvgoUser],
["SvgoWarning", LazySvgoWarning],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
