import { default as _91_46_46_46slug_93ek9sZLmcU3Meta } from "/app/dev/pages/[...slug].vue?macro=true";
import { default as aboutGFX6vKopSpMeta } from "/app/dev/pages/about.vue?macro=true";
import { default as index4DnbR4deMtMeta } from "/app/dev/pages/cart/index.vue?macro=true";
import { default as processingvf6r7D8wsWMeta } from "/app/dev/pages/cart/processing.vue?macro=true";
import { default as constructorWZkEsSVhtZMeta } from "/app/dev/pages/constructor.vue?macro=true";
import { default as contactI0ykuvmXz7Meta } from "/app/dev/pages/contact.vue?macro=true";
import { default as eu_45trains1VCGdeDT32Meta } from "/app/dev/pages/eu-trains.vue?macro=true";
import { default as faqNuv1yoLg3zMeta } from "/app/dev/pages/faq.vue?macro=true";
import { default as _91sectionSlug_93FFjbBPqqJaMeta } from "/app/dev/pages/help/[categorySlug]/[sectionSlug].vue?macro=true";
import { default as indexvWtrICipQ1Meta } from "/app/dev/pages/help/[categorySlug]/index.vue?macro=true";
import { default as helpjhjDPuW963Meta } from "/app/dev/pages/help.vue?macro=true";
import { default as index4PZzyyUOK2Meta } from "/app/dev/pages/index.vue?macro=true";
import { default as mobile_45appd7ApnC9Nv1Meta } from "/app/dev/pages/mobile-app.vue?macro=true";
import { default as my_45ticketsYgON9DhpvHMeta } from "/app/dev/pages/my-tickets.vue?macro=true";
import { default as pagespeedGjNimLdYQ0Meta } from "/app/dev/pages/pagespeed.vue?macro=true";
import { default as refund_45policyjHveF110rvMeta } from "/app/dev/pages/refund-policy.vue?macro=true";
import { default as rulesUFprmZ3OjkMeta } from "/app/dev/pages/rules.vue?macro=true";
import { default as searcha1q3gRF0ygMeta } from "/app/dev/pages/search.vue?macro=true";
import { default as termsCnXjvFhqGyMeta } from "/app/dev/pages/terms.vue?macro=true";
import { default as trainPRndcatIxZMeta } from "/app/dev/pages/train.vue?macro=true";
import { default as _91page_93ctkhjzX3AoMeta } from "/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue?macro=true";
import { default as indexnIaP21Qmh2Meta } from "/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue?macro=true";
import { default as indexbgOZ9gjWYMMeta } from "/app/dev/pages/trains/index.vue?macro=true";
import { default as component_45stubc9b3bdHtLLMeta } from "/app/dev/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubc9b3bdHtLL } from "/app/dev/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ja",
    path: "/ja/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ko",
    path: "/ko/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pt",
    path: "/pt/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ru",
    path: "/ru/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___uk",
    path: "/uk/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___zh",
    path: "/zh/:slug(.*)*",
    meta: _91_46_46_46slug_93ek9sZLmcU3Meta || {},
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___it",
    path: "/it/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ja",
    path: "/ja/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ko",
    path: "/ko/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___pl",
    path: "/pl/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___pt",
    path: "/pt/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___uk",
    path: "/uk/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___zh",
    path: "/zh/about",
    component: () => import("/app/dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "cart___de",
    path: "/de/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___es",
    path: "/es/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___fr",
    path: "/fr/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___it",
    path: "/it/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___ja",
    path: "/ja/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___ko",
    path: "/ko/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___pl",
    path: "/pl/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___pt",
    path: "/pt/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___ru",
    path: "/ru/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___uk",
    path: "/uk/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___zh",
    path: "/zh/cart",
    meta: index4DnbR4deMtMeta || {},
    component: () => import("/app/dev/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___de",
    path: "/de/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___en",
    path: "/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___es",
    path: "/es/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___fr",
    path: "/fr/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___it",
    path: "/it/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___ja",
    path: "/ja/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___ko",
    path: "/ko/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___pl",
    path: "/pl/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___pt",
    path: "/pt/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___ru",
    path: "/ru/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___uk",
    path: "/uk/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "cart-processing___zh",
    path: "/zh/cart/processing",
    meta: processingvf6r7D8wsWMeta || {},
    component: () => import("/app/dev/pages/cart/processing.vue").then(m => m.default || m)
  },
  {
    name: "constructor___de",
    path: "/de/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___en",
    path: "/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___es",
    path: "/es/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___fr",
    path: "/fr/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___it",
    path: "/it/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___ja",
    path: "/ja/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___ko",
    path: "/ko/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___pl",
    path: "/pl/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___pt",
    path: "/pt/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___ru",
    path: "/ru/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___uk",
    path: "/uk/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "constructor___zh",
    path: "/zh/constructor",
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___it",
    path: "/it/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ja",
    path: "/ja/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ko",
    path: "/ko/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pl",
    path: "/pl/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pt",
    path: "/pt/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ru",
    path: "/ru/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___uk",
    path: "/uk/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___zh",
    path: "/zh/contact",
    meta: contactI0ykuvmXz7Meta || {},
    component: () => import("/app/dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___de",
    path: "/de/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___en",
    path: "/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___es",
    path: "/es/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___fr",
    path: "/fr/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___it",
    path: "/it/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___ja",
    path: "/ja/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___ko",
    path: "/ko/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___pl",
    path: "/pl/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___pt",
    path: "/pt/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___ru",
    path: "/ru/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___uk",
    path: "/uk/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "eu-trains___zh",
    path: "/zh/eu-trains",
    component: () => import("/app/dev/pages/eu-trains.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ja",
    path: "/ja/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ko",
    path: "/ko/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___pt",
    path: "/pt/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___uk",
    path: "/uk/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___zh",
    path: "/zh/faq",
    component: () => import("/app/dev/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "help___de",
    path: "/de/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___de",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___de",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___en",
    path: "/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___en",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___en",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___es",
    path: "/es/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___es",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___es",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___fr",
    path: "/fr/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___fr",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___fr",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___it",
    path: "/it/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___it",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___it",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___ja",
    path: "/ja/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___ja",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___ja",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___ko",
    path: "/ko/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___ko",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___ko",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___pl",
    path: "/pl/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___pl",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___pl",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___pt",
    path: "/pt/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___pt",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___pt",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___ru",
    path: "/ru/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___ru",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___ru",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___uk",
    path: "/uk/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___uk",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___uk",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___zh",
    path: "/zh/help",
    component: () => import("/app/dev/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: "help-categorySlug-sectionSlug___zh",
    path: ":categorySlug()/:sectionSlug()",
    meta: _91sectionSlug_93FFjbBPqqJaMeta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/[sectionSlug].vue").then(m => m.default || m)
  },
  {
    name: "help-categorySlug___zh",
    path: ":categorySlug()",
    meta: indexvWtrICipQ1Meta || {},
    component: () => import("/app/dev/pages/help/[categorySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/ja",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ko",
    path: "/ko",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/uk",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___de",
    path: "/de/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___en",
    path: "/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___es",
    path: "/es/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___fr",
    path: "/fr/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___it",
    path: "/it/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___ja",
    path: "/ja/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___ko",
    path: "/ko/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___pl",
    path: "/pl/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___pt",
    path: "/pt/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___ru",
    path: "/ru/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___uk",
    path: "/uk/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "mobile-app___zh",
    path: "/zh/mobile-app",
    component: () => import("/app/dev/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___de",
    path: "/de/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___en",
    path: "/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___es",
    path: "/es/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___fr",
    path: "/fr/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___it",
    path: "/it/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___ja",
    path: "/ja/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___ko",
    path: "/ko/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___pl",
    path: "/pl/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___pt",
    path: "/pt/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___ru",
    path: "/ru/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___uk",
    path: "/uk/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "my-tickets___zh",
    path: "/zh/my-tickets",
    meta: my_45ticketsYgON9DhpvHMeta || {},
    component: () => import("/app/dev/pages/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___de",
    path: "/de/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___en",
    path: "/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___es",
    path: "/es/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___fr",
    path: "/fr/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___it",
    path: "/it/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___ja",
    path: "/ja/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___ko",
    path: "/ko/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___pl",
    path: "/pl/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___pt",
    path: "/pt/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___ru",
    path: "/ru/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___uk",
    path: "/uk/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "pagespeed___zh",
    path: "/zh/pagespeed",
    component: () => import("/app/dev/pages/pagespeed.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___de",
    path: "/de/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___en",
    path: "/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___es",
    path: "/es/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___fr",
    path: "/fr/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___it",
    path: "/it/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___ja",
    path: "/ja/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___ko",
    path: "/ko/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___pl",
    path: "/pl/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___pt",
    path: "/pt/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___ru",
    path: "/ru/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___uk",
    path: "/uk/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "refund-policy___zh",
    path: "/zh/refund-policy",
    meta: refund_45policyjHveF110rvMeta || {},
    component: () => import("/app/dev/pages/refund-policy.vue").then(m => m.default || m)
  },
  {
    name: "rules___de",
    path: "/de/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___en",
    path: "/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___es",
    path: "/es/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___fr",
    path: "/fr/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___it",
    path: "/it/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___ja",
    path: "/ja/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___ko",
    path: "/ko/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___pl",
    path: "/pl/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___pt",
    path: "/pt/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___ru",
    path: "/ru/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___uk",
    path: "/uk/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___zh",
    path: "/zh/rules",
    meta: rulesUFprmZ3OjkMeta || {},
    component: () => import("/app/dev/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___ja",
    path: "/ja/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___ko",
    path: "/ko/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/pl/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pt",
    path: "/pt/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___ru",
    path: "/ru/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___uk",
    path: "/uk/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___zh",
    path: "/zh/search",
    meta: searcha1q3gRF0ygMeta || {},
    component: () => import("/app/dev/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "terms___de",
    path: "/de/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___es",
    path: "/es/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___fr",
    path: "/fr/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___it",
    path: "/it/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___ja",
    path: "/ja/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___ko",
    path: "/ko/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___pl",
    path: "/pl/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___pt",
    path: "/pt/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___ru",
    path: "/ru/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___uk",
    path: "/uk/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___zh",
    path: "/zh/terms",
    meta: termsCnXjvFhqGyMeta || {},
    component: () => import("/app/dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "train___de",
    path: "/de/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___en",
    path: "/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___es",
    path: "/es/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___fr",
    path: "/fr/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___it",
    path: "/it/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___ja",
    path: "/ja/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___ko",
    path: "/ko/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___pl",
    path: "/pl/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___pt",
    path: "/pt/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___ru",
    path: "/ru/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___uk",
    path: "/uk/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "train___zh",
    path: "/zh/train",
    component: () => import("/app/dev/pages/train.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___de",
    path: "/de/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___en",
    path: "/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___es",
    path: "/es/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___fr",
    path: "/fr/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___it",
    path: "/it/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___ja",
    path: "/ja/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___ko",
    path: "/ko/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___pl",
    path: "/pl/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___pt",
    path: "/pt/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___ru",
    path: "/ru/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___uk",
    path: "/uk/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType-page___zh",
    path: "/zh/trains/:countrySlug()/:pageType()/:page()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/[page].vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___de",
    path: "/de/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___en",
    path: "/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___es",
    path: "/es/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___fr",
    path: "/fr/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___it",
    path: "/it/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___ja",
    path: "/ja/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___ko",
    path: "/ko/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___pl",
    path: "/pl/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___pt",
    path: "/pt/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___ru",
    path: "/ru/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___uk",
    path: "/uk/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains-countrySlug-pageType___zh",
    path: "/zh/trains/:countrySlug()/:pageType()",
    component: () => import("/app/dev/pages/trains/[countrySlug]/[pageType]/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___de",
    path: "/de/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___en",
    path: "/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___es",
    path: "/es/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___fr",
    path: "/fr/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___it",
    path: "/it/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___ja",
    path: "/ja/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___ko",
    path: "/ko/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___pl",
    path: "/pl/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___pt",
    path: "/pt/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___ru",
    path: "/ru/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___uk",
    path: "/uk/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: "trains___zh",
    path: "/zh/trains",
    component: () => import("/app/dev/pages/trains/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubc9b3bdHtLLMeta?.name,
    path: "/r",
    component: component_45stubc9b3bdHtLL
  },
  {
    name: component_45stubc9b3bdHtLLMeta?.name,
    path: "/en",
    component: component_45stubc9b3bdHtLL
  }
]